import axios from "axios";
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Trans } from 'gatsby-plugin-react-i18next';

import Button from '../components/Button';
import Card from '../components/Card';
import CardPricing from '../components/CardPricing';

import Layout from '../components/layout/Layout';
import LinkButton from '../components/LinkButton';
import SplitSection from '../components/SplitSection';

import { useForm } from "react-hook-form";


const Index = ({data}) => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async(data) => {
    setSending(true)
    try{
      await axios.post(process.env.GATSBY_FORM_POST_URL, data);
      setSent(true)
    } catch(e) {
      console.log(e.message)
      setError(true)
    } finally {
      setSending(false)
    }
  }
  
  return (
    <Layout>
      <section id="home" className="py-20 md:py-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2 lg:mr-10">
            <h1 className="text-3xl lg:text-4xl xl:text5xl font-bold leading-none">
              <Trans>index.title</Trans>
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              <Trans>index.description</Trans>
            </p>
            <p className="mt-8 md:mt-12">
              <LinkButton 
                size="lg"
                href="https://app.cephalomax.com/login?plan=trial"
                target="_blank"
              >
                <Trans>index.cta.Essai Gratuit</Trans>
              </LinkButton>
            </p>
          </div>
          <div className="lg:w-1/2 lg:p-0 p-10">
            <div className="relative pb-9/16"><iframe src="https://player.vimeo.com/video/733529745?h=d2dd21a5f8&autoplay=1&loop=1&title=0&byline=0&portrait=0&sidedock=0&muted=1" className="absolute top-0 left-O w-full h-full" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            {/* <GatsbyImage image={getImage(data.presentationImage)} /> */}
          </div>
        </div>
      </section>
      <section id="features" className="py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold"><Trans>features</Trans></h2>
          <div className="flex flex-col md:flex-row mt-12 text-black">
            <div className="flex-1 px-3">
              <Card className="mb-8 flex flex-col justify-center items-center">
                {/* <p className="font-semibold text-xl">Classez et standardisez vos photos</p> */}
                <p className="font-semibold text-xl"><Trans>index.features.picturesManagement.title</Trans></p>
                <p className="font-semibold text-xl"><Trans>index.features.picturesManagement.description</Trans></p>
                <div className="mt-4 h-24 w-24 mt-10">
                  <GatsbyImage image={getImage(data.photoCameraImage)} />
                </div>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8 flex flex-col justify-center items-center">
                <p className="font-semibold text-xl"><Trans>index.features.cephaloUtils.title</Trans></p>
                <div className="mt-4 h-24 w-24 mt-10">
                  <GatsbyImage image={getImage(data.headImage)} />
                </div>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8 flex flex-col justify-center items-center">
                <p className="font-semibold text-xl"><Trans>index.features.presentation.title</Trans></p>
                <div className="mt-4 h-24 w-24 mt-10">
                  <GatsbyImage image={getImage(data.internetImage)} />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        id="photo"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight"><Trans>index.features.picturesManagement.subSection.title</Trans></h3>
            <p className="mt-8 text-xl font-light leading-relaxed"><Trans>index.features.picturesManagement.subSection.description</Trans></p>
          </div>
        }
        secondarySlot={<GatsbyImage image={getImage(data.pannelPhotoImage)} />}
      />
      <SplitSection
        id="head"
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight"><Trans>index.features.cephaloUtils.subSection.title</Trans></h3>
            <p className="mt-8 text-xl font-light leading-relaxed"><Trans>index.features.cephaloUtils.subSection.description</Trans></p>
          </div>
        }
        secondarySlot={<GatsbyImage image={getImage(data.headPhotoImage)} />}
      />
      <SplitSection
        id="internet"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight"><Trans>index.features.presentation.subSection.title</Trans></h3>
            <p className="mt-8 text-xl font-light leading-relaxed"><Trans>index.features.presentation.sebSection.description</Trans></p>
          </div>
        }
        secondarySlot={<GatsbyImage image={getImage(data.comparePhotosImage)} />}
      />
      <section id="price" className="py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold"><Trans>prices</Trans></h2>
          <div className="flex flex-col md:flex-row mt-8 lg:px-16 justify-center items-center">
            {/* <div className="flex-1 px-3">
              <CardPricing className="mb-8 flex flex-col items-center">
                <p className="font-semibold text-3xl lg:text-4xl text-primary whitespace-nowrap" style={{whiteSpace: 'nowrap'}}><Trans>index.prices.firstMonth</Trans><sup><Trans>index.prices.firstMonth.sup</Trans></sup><Trans>index.prices.firstMonth.freeMonth</Trans></p>
                <p className="mt-4 text-gray-500 text-xl text-left"><Trans>index.prices.firstMonth.description</Trans></p>
              </CardPricing>
            </div> */}
            <div className="flex-1 px-3 max-w-sm">
              <CardPricing className="mb-8 flex flex-col items-center border-2 border-primary" href="https://app.cephalomax.com/login?plan=annually" target="_blank">
                <div style={{marginTop: -28}}><Trans>index.prices.annually.recommended</Trans></div> 
                <p className="font-semibold text-3xl lg:text-4xl text-primary"><Trans>index.prices.annually.price</Trans><span className="text-gray-500 text-xl"><Trans>index.prices.month</Trans></span></p>
                <p className="mt-4 text-gray-500 text-xl text-left"><Trans>index.prices.annually.description</Trans><b><Trans>index.prices.annually.description.percent</Trans></b></p>
              </CardPricing>
            </div>
            <div className="flex-1 px-3 max-w-sm">
              <CardPricing className="mb-8 flex flex-col items-center" href="https://app.cephalomax.com/login?plan=monthly" target="_blank">
                <p className="font-semibold text-3xl lg:text-4xl text-primary"><Trans>index.prices.monthly.price</Trans><span className="text-gray-500 text-xl"><Trans>index.prices.month</Trans></span></p>
                <p className="mt-4 text-gray-500 text-xl text-left"><Trans>index.prices.monthly.description</Trans></p>
              </CardPricing>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center mt-8 lg:px-16">
            <LinkButton 
              size="xl"
              href="https://app.cephalomax.com/login?plan=trial"
              target="_blank"
            >
              <Trans>index.cta.Premier mois offert</Trans>
            </LinkButton>
          </div>
        </div>
      </section>
      <section className="container mx-auto my-20 py-20 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold"><Trans>index.begin.title</Trans><br/><Trans>index.begin.title.br</Trans></h3>
        <p className="mt-8 text-xl font-light"><Trans>index.begin.description</Trans></p>
        <p className="mt-20">
          <LinkButton 
            size="xl"
            href="https://app.cephalomax.com"
            target="_blank"
          >
            <Trans>index.begin.cta</Trans>
          </LinkButton>
        </p>
      </section>
      <section id="contact" className="py-20">
        <div className="container mx-auto flex flex-col items-center justify-center">
          <h2 className="text-3xl lg:text-5xl font-semibold mb-10"><Trans>contact</Trans></h2>
            { !sent ? (
              <div className="w-4/5 lg:w-1/2">
                <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex justify-between flex-wrap md:flex-nowrap">
                    <label className="flex flex-col flex-grow mb-4 mr-0 md:mr-1 min-w-full md:min-w-0">
                      <span className="text-gray-700"><Trans>index.contactForm.firstname</Trans></span>
                      <input
                        type="text"
                        className={`
                          border
                          rounded-md
                          ${errors.firstname ? 'border-error' : 'border-gray-400'}
                          focus:shadow-none
                          focus:ring-transparent
                          focus:border-primary
                          focus:shadow-primary
                        `}
                        {...register("firstname", { required: true })}
                      />
                      {errors.firstname?.type === 'required' &&  <span className="text-red-600"><Trans>index.contactForm.required</Trans></span>}
                    </label>
                    <label className="flex flex-col flex-grow mb-4 ml-0 md:ml-1 min-w-full md:min-w-0">
                      <span className="text-gray-700"><Trans>index.contactForm.lastname</Trans></span>
                      <input
                        type="text"
                        className={`
                          border
                          rounded-md
                          ${errors.lastname ? 'border-error' : 'border-gray-400'}
                          focus:shadow-none
                          focus:ring-transparent
                          focus:border-primary
                          focus:shadow-primary
                        `}
                        {...register("lastname", { required: true })}
                      />
                      {errors.lastname?.type === 'required' &&  <span className="text-red-600"><Trans>index.contactForm.required</Trans></span>}
                    </label>
                  </div>
                  <label className="flex flex-col mb-4">
                    <span className="text-gray-700"><Trans>index.contactForm.email</Trans></span>
                    <input
                      type="email"
                      className={`
                        border
                        rounded-md
                        ${errors.email ? 'border-error' : 'border-gray-400'}
                        focus:shadow-none
                        focus:ring-transparent
                        focus:border-primary
                        focus:shadow-primary
                      `}
                      {...register("email", { 
                        required: true,
                        pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                      })}
                    />
                    {errors.email?.type === 'required' &&  <span className="text-red-600"><Trans>index.contactForm.required</Trans></span>}
                    {errors.email?.type === 'pattern' &&  <span className="text-red-600">Email invalide</span>}
                  </label>
                  <label className="flex flex-col mb-4">
                    <span className="text-gray-700"><Trans>index.contactForm.message</Trans></span>
                    <textarea
                      className={`
                        border
                        rounded-md
                        ${errors.message ? 'border-error' : 'border-gray-400'}
                        focus:shadow-none
                        focus:ring-transparent
                        focus:border-primary
                        focus:shadow-primary
                        h-40
                      `}
                      {...register("message", { required: true })}
                    />
                    {errors.message?.type === 'required' &&  <span className="text-red-600"><Trans>index.contactForm.required</Trans></span>}
                  </label>
                  <Button type="submit" disabled={sending}>
                    <Trans>index.contactForm.cta</Trans>
                  </Button>
                </form>
                {
                  error ? (
                    <span className="text-red-600"><Trans>index.contactForm.error</Trans></span>
                  ) : null
                }
              </div>
              ) : (
                <div className="border-primary border-2 rounded-md p-5 w-1/3 flex justify-center text-center">
                  <span className="text-gray-500"><Trans>index.contactForm.sent</Trans></span>
                </div>
              )
            }
        </div>
      </section>
    </Layout>
)};

export default Index;

export const query = graphql`
  {
    presentationImage: file(relativePath: {eq: "presentation.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    headImage: file(relativePath: {eq: "head.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    internetImage: file(relativePath: {eq: "internet.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    photoCameraImage: file(relativePath: {eq: "photo-camera.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    pannelPhotoImage: file(relativePath: {eq: "pannel-photo.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    headPhotoImage: file(relativePath: {eq: "cephalo.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    comparePhotosImage: file(relativePath: {eq: "compare-photos.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`