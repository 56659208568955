import React from 'react';

const CardPricing = ({ className, href, target, children }) => href ? (
  <a
    href={href}
    target={target}
    className={`p-8 rounded-lg min-h-full ${className} hover:bg-slate-50`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
    >
    {children}
  </a>
) : (
  <div
    className={`p-8 rounded-lg min-h-full ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
    >
    {children}
  </div>
);

export default CardPricing;
